<header>
    <section class="container">
        <div class="header-outer col-sm-12">
            <div class="header-logo col-sm-8 col-xs-12">
                <a routerLink="/home"><img src="assets/img/mag-logo.png" alt="magzter-logo"></a>            
            </div>
            <div class="nav-menu col-sm-4 col-xs-12">
               <app-navmenu></app-navmenu>
            </div>
        </div>
    </section>
</header>