import { Component } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

@Component({
  selector: 'app-root',
  template:`<app-header></app-header>
  <router-outlet></router-outlet>
  <app-scroll-tp></app-scroll-tp>
  <app-footer></app-footer>`,
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'Magzter';
}
